// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-wrapper {
  display: flex;
  justify-content: center;
}
.profile-wrapper .tabs-wrapper {
  width: 1280px;
}
.profile-wrapper .chakra-tabs__tab-panel {
  padding: 0px;
}
.profile-wrapper .content {
  width: 1280px;
}
.profile-wrapper .content .your-home-title {
  text-align: left;
  font-size: 36px;
  font-weight: 700;
  color: #2A2A33;
}
.profile-wrapper .content .header-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 30px 0px;
}
.profile-wrapper .content .header-wrapper .delete-home {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.profile-wrapper .content .header-wrapper .delete-home p {
  font-weight: 700;
  color: rgb(44, 104, 246);
}
.profile-wrapper .content .header-wrapper .delete-home:hover {
  cursor: pointer;
}
.profile-wrapper .content .header-wrapper .delete-home:hover p {
  text-decoration: underline;
}
.profile-wrapper .content .listing-cards-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/UserProfile/UserProfile.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;AACJ;AAEI;EACI,aAAA;AAAR;AAGI;EACI,YAAA;AADR;AAII;EACI,aAAA;AAFR;AAIQ;EACI,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;AAFZ;AAKQ;EACI,aAAA;EACA,8BAAA;EACA,iBAAA;AAHZ;AAKY;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;AAHhB;AAKgB;EACI,gBAAA;EACA,wBAAA;AAHpB;AAMgB;EACI,eAAA;AAJpB;AAMoB;EACI,0BAAA;AAJxB;AAUQ;EACI,aAAA;EACA,eAAA;EACA,2BAAA;EACA,SAAA;AARZ","sourcesContent":[".profile-wrapper {\n    display: flex;\n    justify-content: center;\n\n\n    .tabs-wrapper {\n        width: 1280px;\n    }\n\n    .chakra-tabs__tab-panel {\n        padding: 0px;\n    }\n\n    .content {\n        width: 1280px;\n\n        .your-home-title {\n            text-align: left;\n            font-size: 36px;\n            font-weight: 700;\n            color: #2A2A33;\n        }\n\n        .header-wrapper {\n            display: flex;\n            justify-content: space-between;\n            padding: 30px 0px;\n\n            .delete-home {\n                display: flex;\n                justify-content: center;\n                align-items: center;\n                gap: 10px;\n\n                p {\n                    font-weight: 700;\n                    color: rgb(44, 104, 246);\n                }\n\n                &:hover {\n                    cursor: pointer;\n\n                    p {\n                        text-decoration: underline;\n                    }\n                }\n            }\n        }\n\n        .listing-cards-wrapper {\n            display: flex;\n            flex-wrap: wrap;\n            justify-content: flex-start;\n            gap: 20px;\n\n        }\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
