// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  width: 100%;
}

.beds-and-baths {
  padding: 15px;
}
.beds-and-baths label legend {
  font-size: 15px;
  color: #2A2A33;
}`, "",{"version":3,"sources":["webpack://./src/components/SearchBar/FilterButtons/BedsAndBaths/BedsAndBaths.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,aAAA;AACF;AAEI;EACE,eAAA;EACA,cAAA;AAAN","sourcesContent":[".title {\n  width: 100%;\n}\n\n.beds-and-baths {\n  padding: 15px;\n\n  label {\n    legend {\n      font-size: 15px;\n      color: #2A2A33;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
