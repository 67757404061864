// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.squares-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 1px;
  font-weight: 700;
  font-size: 0.8rem;
}
.squares-container .bedsandbaths-square:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.squares-container .bedsandbaths-square:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.bedsandbaths-square {
  outline: 1px solid #999;
  padding: 13px 19px;
  text-align: center;
  -webkit-user-select: none;
          user-select: none;
  z-index: 1;
}
.bedsandbaths-square:hover {
  background-color: #f5f5f5;
}

.selected-square {
  background-color: rgb(243, 250, 254);
  outline: 1px solid rgb(44, 104, 246);
  border: 1px double rgb(44, 104, 246);
  z-index: 2;
  padding: 12px 18px;
}
.selected-square:hover {
  background-color: rgb(243, 250, 254);
}`, "",{"version":3,"sources":["webpack://./src/components/SearchBar/FilterButtons/BedsAndBaths/Squares.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qCAAA;EACA,aAAA;EACA,gBAAA;EACA,iBAAA;AACF;AACE;EACE,2BAAA;EACA,8BAAA;AACJ;AAEE;EACE,4BAAA;EACA,+BAAA;AAAJ;;AAIA;EACE,uBAAA;EACA,kBAAA;EACA,kBAAA;EACA,yBAAA;UAAA,iBAAA;EACA,UAAA;AADF;AAGE;EACE,yBAAA;AADJ;;AAKA;EACE,oCAAA;EACA,oCAAA;EACA,oCAAA;EACA,UAAA;EACA,kBAAA;AAFF;AAIE;EACE,oCAAA;AAFJ","sourcesContent":[".squares-container {\n  display: grid;\n  grid-template-columns: repeat(6, 1fr);\n  grid-gap: 1px;\n  font-weight: 700;\n  font-size: 0.8rem;\n\n  .bedsandbaths-square:first-child {\n    border-top-left-radius: 3px;\n    border-bottom-left-radius: 3px;\n  }\n\n  .bedsandbaths-square:last-child {\n    border-top-right-radius: 3px;\n    border-bottom-right-radius: 3px;\n  }\n}\n\n.bedsandbaths-square {\n  outline: 1px solid #999;\n  padding: 13px 19px;\n  text-align: center;\n  user-select: none;\n  z-index: 1;\n\n  &:hover {\n    background-color: #f5f5f5;\n  }\n}\n\n.selected-square {\n  background-color: rgb(243 250 254);\n  outline: 1px solid rgb(44 104 246);\n  border: 1px double rgb(44 104 246);\n  z-index: 2;\n  padding: 12px 18px;\n\n  &:hover {\n    background-color: rgb(243 250 254);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
