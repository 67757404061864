// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.price-listPriceDropDown {
  background-color: white;
  max-height: 220px;
  overflow-y: scroll;
  width: 146px;
  margin-top: 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}
.price-listPriceDropDown li {
  list-style: none;
  padding: 10px;
  font-size: 16px;
}
.price-listPriceDropDown li:hover {
  background-color: #F4FAFF;
}`, "",{"version":3,"sources":["webpack://./src/components/SearchBar/FilterButtons/PriceRange/PriceDropDown.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,iBAAA;EACA,kBAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;EACA,UAAA;AACF;AACE;EACE,gBAAA;EACA,aAAA;EACA,eAAA;AACJ;AACI;EACE,yBAAA;AACN","sourcesContent":[".price-listPriceDropDown {\n  background-color: white;\n  max-height: 220px;\n  overflow-y: scroll;\n  width: 146px;\n  margin-top: 10px;\n  border-radius: 6px;\n  position: absolute;\n  z-index: 1;\n\n  li {\n    list-style: none;\n    padding: 10px;\n    font-size: 16px;\n\n    &:hover{\n      background-color: #F4FAFF;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
