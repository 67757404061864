// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index-empty-search-wrapper .index-current-location {
  display: flex;
  align-items: center;
}
.index-empty-search-wrapper .index-current-location .index-location-icon {
  color: rgb(96, 106, 128);
  width: 22px;
  height: 18px;
  margin: 14px 10px 14px 20px;
}
.index-empty-search-wrapper .index-current-location:hover {
  background-color: rgb(244, 250, 254);
}
.index-empty-search-wrapper ul li.index-search-history-list-item {
  align-items: center;
  display: flex;
  list-style: none;
}
.index-empty-search-wrapper ul li.index-search-history-list-item .index-clock-icon {
  color: rgb(96, 106, 128);
  width: 16px;
  height: 16px;
  margin: 10px 14px 10px 22px;
}
.index-empty-search-wrapper ul li.index-search-history-list-item .suggestion-item-index p {
  margin-left: 0;
  color: #000;
  border-bottom: none;
}
.index-empty-search-wrapper ul li.index-search-history-list-item:hover {
  background-color: rgb(244, 250, 254);
}
.index-empty-search-wrapper ul li.index-search-history-list-item:last-child:hover {
  background-color: rgb(244, 250, 254);
  border-radius: 0 0 8px 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/SearchBar/IndexSearchHistorySuggestions.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;AAAJ;AAEI;EACE,wBAAA;EACA,WAAA;EACA,YAAA;EACA,2BAAA;AAAN;AAGI;EACE,oCAAA;AADN;AAMI;EACE,mBAAA;EACA,aAAA;EACA,gBAAA;AAJN;AAMM;EACE,wBAAA;EACA,WAAA;EACA,YAAA;EACA,2BAAA;AAJR;AAQQ;EACE,cAAA;EACA,WAAA;EACA,mBAAA;AANV;AAUM;EACE,oCAAA;AARR;AAUM;EACE,oCAAA;EACA,0BAAA;AARR","sourcesContent":[".index-empty-search-wrapper {\n  .index-current-location {\n    display: flex;\n    align-items: center;\n\n    .index-location-icon {\n      color: rgb(96 106 128);\n      width: 22px;\n      height: 18px;\n      margin: 14px 10px 14px 20px;\n    }\n\n    &:hover {\n      background-color: rgb(244 250 254);\n    }\n  }\n\n  ul {\n    li.index-search-history-list-item {\n      align-items: center;\n      display: flex;\n      list-style: none;\n\n      .index-clock-icon {\n        color: rgb(96 106 128);\n        width: 16px;\n        height: 16px;\n        margin: 10px 14px 10px 22px;\n      }\n\n      .suggestion-item-index {\n        p {\n          margin-left: 0;\n          color: #000;\n          border-bottom: none;\n        }\n      }\n\n      &:hover {\n        background-color: rgb(244 250 254);\n      }\n      &:last-child:hover {\n        background-color: rgb(244 250 254);\n        border-radius: 0 0 8px 8px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
