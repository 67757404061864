// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel-container {
  padding: 13px 0px 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1240px;
  position: relative;
  flex-direction: column;
  gap: 10px;
}
.carousel-container .header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 99%;
}
.carousel-container .header-container .splat_listing_container__headers {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 5px;
  gap: 10px;
}
.carousel-container .header-container .splat_listing_container__headers h1 {
  font-size: 20px;
  color: #2a2a33;
  font-weight: 600;
  line-height: 10px;
  font-family: "Open Sans", "Time New Roman", arial !important;
}
.carousel-container .header-container .splat_listing_container__headers p {
  font-size: 14px;
  font-weight: 400;
  color: rgb(89, 107, 130);
}
.carousel-container .carousel-container__swiper {
  width: 1220px;
  height: 100%;
  z-index: 0;
  display: flex;
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/ListingsCarousel/Carousel.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,kBAAA;EACA,sBAAA;EACA,SAAA;AACF;AACI;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,UAAA;AACN;AACM;EACE,aAAA;EACA,2BAAA;EACA,sBAAA;EACA,uBAAA;EACA,kBAAA;EACA,SAAA;AACR;AACQ;EACE,eAAA;EACA,cAAA;EACA,gBAAA;EACA,iBAAA;EACA,4DAAA;AACV;AAEQ;EACE,eAAA;EACA,gBAAA;EACA,wBAAA;AAAV;AAKI;EACE,aAAA;EACA,YAAA;EACA,UAAA;EACA,aAAA;EACA,aAAA;AAHN","sourcesContent":[".carousel-container {\n  padding: 13px 0px 20px 0px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 1240px;\n  position: relative;\n  flex-direction: column;\n  gap: 10px;\n\n    .header-container {\n      display: flex;\n      justify-content: space-between;\n      align-items: center;\n      width: 99%;\n\n      .splat_listing_container__headers {\n        display: flex;\n        justify-content: flex-start;\n        flex-direction: column;\n        align-items: flex-start;\n        padding: 0 0 0 5px;\n        gap: 10px;\n\n        h1 {\n          font-size: 20px;\n          color: #2a2a33;\n          font-weight: 600;\n          line-height: 10px;\n          font-family: \"Open Sans\", \"Time New Roman\", arial !important;\n        }\n\n        p {\n          font-size: 14px;\n          font-weight: 400;\n          color: rgb(89, 107, 130);\n        }\n      }\n    }\n\n    .carousel-container__swiper {\n      width: 1220px;\n      height: 100%;\n      z-index: 0;\n      display: flex;\n      padding: 10px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
