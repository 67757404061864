// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.line-separator {
  background-color: rgb(209, 209, 213);
  height: 0.01vh;
}

.personal-links__title {
  display: flex;
  justify-content: center;
}

.personal-links {
  font-weight: 400;
  margin-top: 1vh;
  display: flex;
  flex-direction: column;
  gap: 2vh;
}
.personal-links .personal-links__buttons {
  display: flex;
  flex-direction: column;
  gap: 1.1vw;
  margin-bottom: 5px;
}
.personal-links .personal-links__buttons .external-links {
  text-decoration: none;
}
.personal-links .personal-links__buttons .positioning {
  display: flex;
  justify-content: space-between;
  padding: 0px 10vh 0px 3.7vh;
}
.personal-links .personal-links__buttons .gitbug-btn {
  color: white;
  background-color: black;
}
.personal-links .personal-links__buttons .gitbug-btn:hover {
  background-color: white;
  border: 1px solid black;
  color: black;
}
.personal-links .personal-links__buttons .linkedIn-btn {
  background-color: rgb(44, 104, 246);
  color: white;
}
.personal-links .personal-links__buttons .linkedIn-btn:hover {
  background-color: white;
  border: 1px solid #1f4494;
  color: #1f4494;
}
.personal-links .personal-links__buttons .angelList-btn:hover {
  background-color: white;
  border: 1px solid black;
}
.personal-links Button {
  font-weight: 400;
  border-radius: 3px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Authentication/FollowButtonLinks.scss"],"names":[],"mappings":"AAAA;EACE,oCAAA;EACA,cAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;AACF;;AAEA;EACE,gBAAA;EACA,eAAA;EACA,aAAA;EACA,sBAAA;EACA,QAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,UAAA;EACA,kBAAA;AACJ;AACI;EACE,qBAAA;AACN;AAEI;EACE,aAAA;EACA,8BAAA;EACA,2BAAA;AAAN;AAGI;EACE,YAAA;EACA,uBAAA;AADN;AAGM;EACE,uBAAA;EACA,uBAAA;EACA,YAAA;AADR;AAKI;EACE,mCAAA;EACA,YAAA;AAHN;AAKM;EACE,uBAAA;EACA,yBAAA;EACA,cAAA;AAHR;AAQM;EACE,uBAAA;EACA,uBAAA;AANR;AAWE;EACE,gBAAA;EACA,kBAAA;EACA,WAAA;AATJ","sourcesContent":[".line-separator {\n  background-color: rgb(209 209 213);\n  height: 0.01vh;\n}\n\n.personal-links__title {\n  display: flex;\n  justify-content: center;\n}\n\n.personal-links {\n  font-weight: 400;\n  margin-top: 1vh;\n  display: flex;\n  flex-direction: column;\n  gap: 2vh;\n\n  .personal-links__buttons {\n    display: flex;\n    flex-direction: column;\n    gap: 1.1vw;\n    margin-bottom: 5px;\n\n    .external-links {\n      text-decoration: none;\n    }\n\n    .positioning {\n      display: flex;\n      justify-content: space-between;\n      padding: 0px 10vh 0px 3.7vh;\n    }\n\n    .gitbug-btn {\n      color: white;\n      background-color: black;\n\n      &:hover {\n        background-color: white;\n        border: 1px solid black;\n        color: black;\n      }\n    }\n\n    .linkedIn-btn {\n      background-color: rgb(44 104 246);\n      color: white;\n\n      &:hover {\n        background-color: white;\n        border: 1px solid #1f4494;\n        color: #1f4494;\n      }\n    }\n\n    .angelList-btn {\n      &:hover {\n        background-color: white;\n        border: 1px solid black;\n      }\n    }\n  }\n\n  Button {\n    font-weight: 400;\n    border-radius: 3px;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
