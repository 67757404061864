// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index-page-container {
  width: 100vw;
  height: calc(100vh - 135px);
}
.index-page-container .index-page-content-container {
  width: 100%;
  flex-grow: 1;
  height: 100%;
}

@media only screen and (min-width: 2251px) {
  div .index-page-content-container {
    display: grid;
    grid-template-columns: 3.01fr 1fr;
  }
}
@media only screen and (max-width: 2250px) {
  div .index-page-content-container {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }
}
@media only screen and (max-width: 1850px) {
  div .index-page-content-container {
    display: grid;
    grid-template-columns: 2fr 1.3fr;
  }
}
@media only screen and (max-width: 1550px) {
  div .index-page-content-container {
    display: grid;
    grid-template-columns: 1.5fr 1.4fr;
  }
}
@media only screen and (max-width: 1350px) {
  div .index-page-content-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (max-width: 1250px) {
  div .index-page-content-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }
}
@media only screen and (max-width: 850px) {
  div .index-page-content-container {
    display: grid;
    grid-template-columns: 0fr 1fr;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/IndexPage/IndexPage.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,2BAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,YAAA;AACJ;;AAGA;EAEI;IACE,aAAA;IACA,iCAAA;EADJ;AACF;AAKA;EAEI;IACE,aAAA;IACA,8BAAA;EAJJ;AACF;AAQA;EAEI;IACE,aAAA;IACA,gCAAA;EAPJ;AACF;AAWA;EAEI;IACE,aAAA;IACA,kCAAA;EAVJ;AACF;AAcA;EAEI;IACE,aAAA;IACA,8BAAA;EAbJ;AACF;AAiBA;EAEI;IACE,aAAA;IACA,8BAAA;EAhBJ;AACF;AAoBA;EAEI;IACE,aAAA;IACA,8BAAA;EAnBJ;AACF","sourcesContent":[".index-page-container {\n  width: 100vw;\n  height: calc(100vh - 135px);\n\n  .index-page-content-container {\n    width: 100%;\n    flex-grow: 1;\n    height: 100%;\n  }\n}\n\n@media only screen and (min-width: 2251px) {\n  div {\n    .index-page-content-container{\n      display: grid;\n      grid-template-columns: 3.01fr 1fr;\n    }\n  }\n}\n\n@media only screen and (max-width: 2250px) {\n  div {\n    .index-page-content-container{\n      display: grid;\n      grid-template-columns: 2fr 1fr;\n    }\n  }\n}\n\n@media only screen and (max-width: 1850px) {\n  div {\n    .index-page-content-container{\n      display: grid;\n      grid-template-columns: 2fr 1.3fr;\n    }\n  }\n}\n\n@media only screen and (max-width: 1550px) {\n  div {\n    .index-page-content-container{\n      display: grid;\n      grid-template-columns: 1.5fr 1.4fr;\n    }\n  }\n}\n\n@media only screen and (max-width: 1350px) {\n  div {\n    .index-page-content-container{\n      display: grid;\n      grid-template-columns: 1fr 1fr;\n    }\n  }\n}\n\n@media only screen and (max-width: 1250px) {\n  div {\n    .index-page-content-container{\n      display: grid;\n      grid-template-columns: 1fr 2fr;\n    }\n  }\n}\n\n@media only screen and (max-width: 850px) {\n  div {\n    .index-page-content-container{\n      display: grid;\n      grid-template-columns: 0fr 1fr;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
