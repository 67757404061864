// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.suggestion-item-splash {
  display: flex;
  align-items: center;
  height: 51px;
  width: 100%;
  width: 95%;
}
.suggestion-item-splash p {
  margin-left: 30px;
  color: #807e7e;
  font-weight: 500;
}
.suggestion-item-splash .highlighted {
  color: black;
  font-weight: 500;
  margin: 0px;
}

.suggestion-item-index {
  display: flex;
  align-items: center;
  height: 51px;
  width: 100%;
}
.suggestion-item-index p {
  margin-left: 30px;
  color: #807e7e;
  font-weight: 500;
}
.suggestion-item-index .highlighted {
  color: black;
  font-weight: 500;
  margin: 0px;
}
.suggestion-item-index:hover {
  background-color: rgb(243, 250, 254);
  border-radius: 6px;
}`, "",{"version":3,"sources":["webpack://./src/components/SearchBar/SuggestionItem.scss"],"names":[],"mappings":"AAmBA;EAlBE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;EAiBA,UAAA;AAfF;AAAE;EACE,iBAAA;EACA,cAAA;EACA,gBAAA;AAEJ;AACE;EACE,YAAA;EACA,gBAAA;EACA,WAAA;AACJ;;AASA;EAxBE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;AAmBF;AAjBE;EACE,iBAAA;EACA,cAAA;EACA,gBAAA;AAmBJ;AAhBE;EACE,YAAA;EACA,gBAAA;EACA,WAAA;AAkBJ;AALE;EACE,oCAAA;EACA,kBAAA;AAOJ","sourcesContent":["@mixin shared-styles {\n  display: flex;\n  align-items: center;\n  height: 51px;\n  width: 100%;\n\n  p {\n    margin-left: 30px;\n    color: #807e7e;\n    font-weight: 500;\n  }\n\n  .highlighted {\n    color: black;\n    font-weight: 500;\n    margin: 0px;\n  }\n}\n\n.suggestion-item-splash {\n  @include shared-styles;\n  width: 95%;\n}\n\n\n.suggestion-item-index {\n  @include shared-styles;\n\n  &:hover {\n    background-color: rgb(243 250 254);\n    border-radius: 6px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
