// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile_card {
  flex: 0 0 32.2%;
  padding: 30px 20px;
  height: 610px;
}`, "",{"version":3,"sources":["webpack://./src/components/UserProfile/ProfileCard.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,kBAAA;EACA,aAAA;AACJ","sourcesContent":[".profile_card {\n    flex: 0 0 32.2%;\n    padding: 30px 20px;\n    height: 610px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
