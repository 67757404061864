// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-nav-buttons {
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 5px;
  width: 7%;
}

.btn {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(167, 166, 171);
  color: rgb(42, 42, 50);
  border-radius: 50%;
  width: 35px;
  height: 35px;
}
.btn .btn__icon {
  font-size: 15px;
}
.btn:hover {
  background-color: rgb(241, 241, 241);
}

.swiper-button-disabled {
  opacity: 0.3;
}`, "",{"version":3,"sources":["webpack://./src/components/ListingsCarousel/CarouselButton.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,iBAAA;EACA,iBAAA;EACA,SAAA;AACF;;AAEA;EACE,oCAAA;EACA,oCAAA;EACA,sBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AACF;AACE;EACE,eAAA;AACJ;AAEE;EACE,oCAAA;AAAJ;;AAIA;EACE,YAAA;AADF","sourcesContent":[".custom-nav-buttons {\n  display: flex;\n  justify-content: space-between;\n  margin-left: 20px;\n  margin-right: 5px;\n  width: 7%;\n}\n\n.btn {\n  background-color: rgb(255 255 255);\n  border: 1px solid rgb(167 166 171);\n  color: rgb(42 42 50);\n  border-radius: 50%;\n  width: 35px;\n  height: 35px;\n\n  .btn__icon {\n    font-size: 15px;\n  }\n\n  &:hover {\n    background-color: rgb(241 241 241);\n  }\n}\n\n.swiper-button-disabled {\n  opacity: 0.3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
