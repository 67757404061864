import React from "react";

export const Building = () => {
	return (
		<svg
			viewBox="0 0 32 32"
			aria-hidden="true"
			focusable="false"
			role="img"
			width={22}
			height={22}
		>
			<title>House Type</title>
			<g stroke="none">
				<path d="M28.34 7.06l-14-5a1 1 0 00-.92.12A1 1 0 0013 3v13.35l-2.33-2.09a1 1 0 00-1.34 0l-5.82 5.22A1.55 1.55 0 003 20.62v8.11A1.27 1.27 0 004.27 30H28a1 1 0 001-1V8a1 1 0 00-.66-.94zM11 28H9v-3h2zm4 0h-2v-3.5a1.5 1.5 0 00-1.5-1.5h-3A1.5 1.5 0 007 24.5V28H5v-7.17l5-4.49 5 4.49zm12 0H17v-7.38a1.55 1.55 0 00-.51-1.14L15 18.12V4.42l12 4.29z"></path>
				<path d="M24 13v10a1 1 0 01-2 0V13a1 1 0 012 0zM20 11v12a1 1 0 01-2 0V11a1 1 0 012 0z"></path>
			</g>
		</svg>
	);
};

export const Calendar = () => {
	return (
		<svg
			viewBox="0 0 32 32"
			aria-hidden="true"
			focusable="false"
			role="img"
			width={22}
			height={22}
		>
			<title>Hammer</title>
			<g stroke="none">
				<path d="M15.48 25a1 1 0 001 1 1 1 0 10-1-1z"></path>
				<path d="M28.38 6a5 5 0 00-4-2h-.58A3 3 0 0021 2h-9a3 3 0 00-3 3h-.06a2.52 2.52 0 00-2.46-2h-1A2.51 2.51 0 003 5.51v5A2.52 2.52 0 005.52 13h1a2.52 2.52 0 002.46-2H9a3 3 0 003 3h.88L12 25.35v.16a4.4 4.4 0 002.54 4.07A4.53 4.53 0 0021 25.5v-.15L20.12 14H21a3 3 0 003-3v-1h.67l.8.26a2.49 2.49 0 00.85.14A2.69 2.69 0 0029 8.16 2.81 2.81 0 0028.38 6zM19 25.5a2.5 2.5 0 11-5 0l.87-11.5h3.22zm7.3-17.1h-.23l-.79-.27a1.93 1.93 0 00-.63-.1H23a1 1 0 00-1 1v2a1 1 0 01-1 1h-9a1 1 0 01-1-1V10a1 1 0 00-1-1H8a1 1 0 00-1 1v.48a.52.52 0 01-.52.52h-1a.52.52 0 01-.48-.52v-5A.52.52 0 015.52 5h1a.52.52 0 01.48.52V6a1 1 0 001 1h2a1 1 0 001-1V5a1 1 0 011-1h9a1 1 0 011 1 1 1 0 001 1h1.39a3 3 0 012.49 1.34.68.68 0 01-.58 1.06z"></path>
			</g>
		</svg>
	);
};

export const HOA = () => {
	return (
		<svg viewBox="0 0 32 32" aria-hidden="true" width={24} height={24}>
			<path d="M14.31 20.81l-3.94-4.75-.09-.1A2.91 2.91 0 008 15.2v-2.7C8 9.92 6.38 9 5 9s-3 1.08-3 3.5v7.2a3.74 3.74 0 00.82 2.3L7 27.25V29a1 1 0 001 1h6a1 1 0 001-1v-6.28a3 3 0 00-.69-1.91zM13 28H9v-1.1a1 1 0 00-.22-.62l-4.4-5.51A1.67 1.67 0 014 19.7v-7.2c0-.68.17-1.5 1-1.5.25 0 1 0 1 1.5v3.85A3 3 0 006.23 20l3 3.6a1 1 0 001.54-1.28l-3-3.64a1 1 0 01-.06-1.28.86.86 0 011.2 0l3.89 4.68a1 1 0 01.23.64zM27 9c-1.38 0-3 .92-3 3.5v2.69a2.93 2.93 0 00-2.28.77l-.09.1-3.94 4.75a3 3 0 00-.69 1.91V29a1 1 0 001 1h6a1 1 0 001-1v-1.75L29.18 22a3.74 3.74 0 00.82-2.3v-7.2c0-2.42-1.51-3.5-3-3.5zm1 10.7a1.71 1.71 0 01-.38 1.08l-4.4 5.5a1 1 0 00-.22.62V28h-4v-5.28a1 1 0 01.23-.64l3.89-4.68a.84.84 0 011.14 0 1 1 0 010 1.38l-3 3.6a1 1 0 101.54 1.28l3-3.56a3 3 0 00.2-3.73V12.5c0-1.5.75-1.5 1-1.5.83 0 1 .82 1 1.5z"></path>
		</svg>
	);
};

export const SQFT = () => {
	return (
		<svg
			viewBox="0 0 32 32"
			aria-hidden="true"
			focusable="false"
			role="img"
			width={22}
			height={22}
		>
			<title>Price Square Feet</title>
			<g stroke="none">
				<path d="M29 21H11V3a1.003 1.003 0 00-1-1H3a1.003 1.003 0 00-1 1v26a1.003 1.003 0 001 1h26a1.003 1.003 0 001-1v-7a1.003 1.003 0 00-1-1zm-1 7H4V4h5v2H8a1 1 0 000 2h1v3H8a1 1 0 000 2h1v3H8a1 1 0 000 2h1v5h5v1a1 1 0 002 0v-1h3v1a1 1 0 002 0v-1h3v1a1 1 0 002 0v-1h2z"></path>
				<path d="M20.038 14.587a2.882 2.882 0 01-1.947-.831 1.347 1.347 0 00-.926-.419.802.802 0 00-.865.825 1.578 1.578 0 00.595 1.141 4.027 4.027 0 002.19.883v.885a.929.929 0 001.858 0v-.913a2.949 2.949 0 002.757-2.875c0-1.385-.838-2.23-2.54-2.562l-1.278-.263c-.858-.17-1.25-.494-1.25-1 0-.568.506-1.02 1.303-1.02a2.31 2.31 0 011.737.763 1.414 1.414 0 00.98.419.751.751 0 00.777-.757 1.622 1.622 0 00-.58-1.136 3.7 3.7 0 00-1.907-.856v-.943a.929.929 0 00-1.857 0v.927a2.834 2.834 0 00-2.67 2.772c0 1.378.824 2.257 2.46 2.595l1.27.277c.974.21 1.359.507 1.359 1.014 0 .649-.514 1.074-1.466 1.074z"></path>
			</g>
		</svg>
	);
};

export const SQFTLOT = () => {
	return (
		<svg
			viewBox="0 0 32 32"
			aria-hidden="true"
			focusable="false"
			role="img"
			width={22}
			height={22}
		>
			<title>Lot</title>
			<path
				stroke="none"
				d="M30.9 26.4l-5.6-8a1 1 0 00-.8-.4H13v-2a4.7 4.7 0 004-4.5 4.3 4.3 0 00-1.6-3.3 4 4 0 10-6.8 0A4.3 4.3 0 007 11.5a4.7 4.7 0 004 4.4V18H7.5a1 1 0 00-.8.4l-5.6 8a1 1 0 00.8 1.6h28.2a1 1 0 00.8-1.6zM9 11.5a2.3 2.3 0 011-1.8 2 2 0 00.3-2.6A2 2 0 0110 6a2 2 0 014 0 2 2 0 01-.3 1 2 2 0 00.4 2.7 2.3 2.3 0 01.9 1.8 2.6 2.6 0 01-2 2.4V11a1 1 0 00-2 0v2.9a2.6 2.6 0 01-2-2.4zM3.8 26L8 20h3v2a1 1 0 002 0v-2h11l4.2 6z"
			></path>
		</svg>
	);
};

export const ZESTIMATE = () => {
	return (
		<svg
			viewBox="0 0 32 32"
			aria-hidden="true"
			focusable="false"
			role="img"
			width={22}
			height={22}
		>
			<title>Zillow Logo</title>
			<g stroke="none">
				<path d="M20.58 9.55c.13 0 .19 0 .27.1.44.49 1.88 2.21 2.28 2.68a.16.16 0 010 .21 51.41 51.41 0 00-7.93 7.61 92.18 92.18 0 0113.83-4V12L16 2 3 12v4.57a92.37 92.37 0 0117.58-7.02z"></path>
				<path d="M10 26.86a.22.22 0 01-.28 0L7.3 24c-.07-.08-.07-.12 0-.25a45.52 45.52 0 018.14-8.66s0-.06 0 0A108.81 108.81 0 003 20.37V30h26v-9.26c-3.55.59-14.16 3.68-19 6.12z"></path>
			</g>
		</svg>
	);
};

export const Logo = () => {
	return (
		<svg viewBox="0 0 162 34" width="140" height="32">
			<title id="zillow-logo-title">Zillow</title>
			<path
				fill="rgb(0, 106, 255)"
				d="M124 12.6h6.7l2 6.2a253.4 253.4 0 011.7 6l1.7-6 2-6.2h4.9l2 6.2a230.6 230.6 0 011.7 6s1-3.9 1.7-6l2-6.2h6l-6.6 20.6h-6l-1.6-5a413.5 413.5 0 01-1.9-6s-1 3.8-1.8 5.9l-1.6 5.4h-6.2L124 12.6zM86.2 1.8h-6.7v31.7h6.7V1.8zM98 1.8h-6.8v31.7h6.7V1.8zM117.3 23c0-3-2.2-5.1-4.8-5.1-2.7 0-4.9 2-4.9 5.1 0 3.1 2.2 5.2 4.9 5.2 2.6 0 4.8-2 4.8-5.2zm-16.1 0c0-6.2 5-10.9 11.3-10.9a11 11 0 110 22 11 11 0 01-11.3-11M40.8 4.7v5.9h11.9v.2L41 31l-.4 1.1v1.4h22.8v-6.2H50.6l12-20.5.2-.8V4.7h-22zM67.7 12.6h6.8v20.9h-6.8V12.6zm3.4-10.3A3.8 3.8 0 0175 6a3.8 3.8 0 11-7.7 0 3.8 3.8 0 013.8-3.7M20.6 9a.3.3 0 01.3.2 163.3 163.3 0 012.7 3.4 61.4 61.4 0 00-9.3 9.2c3.7-1.6 12.3-4.1 16.2-4.8v-5L15.3 0 0 12v5.4c4.7-2.8 15.7-7.1 20.6-8.4z"
			></path>
			<path
				fill="rgb(0, 106, 255)"
				d="M8.2 29.7a.3.3 0 01-.3 0L5 26.3V26a53.6 53.6 0 019.6-10.4C11.6 16.6 3 20.2 0 22v11.5h30.5v-11c-4.2.6-16.6 4.3-22.3 7.2z"
			></path>
		</svg>
	);
};

export const Heart = () => {
	return (
		<svg viewBox="0 0 32 32" aria-hidden="true" width={24} height={24}>
			<title>Heart</title>
			<path
				fill="rgb(0, 106, 255)"
				stroke="none"
				d="M27.66 6.19a7.85 7.85 0 00-11 .13L16 7l-.65-.66a7.85 7.85 0 00-11-.13 8.23 8.23 0 00.09 11.59l.42.42L15.29 28.7a1 1 0 001.42 0l10.44-10.5.42-.42a8.23 8.23 0 00.09-11.59zm-1.42 10.06l-.52.52L16 26.55l-9.72-9.78-.52-.52A6.15 6.15 0 014 13.19a5.91 5.91 0 011.62-5.43 5.81 5.81 0 014.67-1.71 6 6 0 013.78 1.87l.5.5 1.08 1.08a.5.5 0 00.7 0l1.08-1.08.5-.5a6 6 0 013.78-1.87 5.81 5.81 0 014.67 1.71A5.91 5.91 0 0128 13.19a6.15 6.15 0 01-1.76 3.06z"
			></path>
		</svg>
	);
};

export const Share = () => {
	return (
		<svg viewBox="0 0 24 24" aria-hidden="true" width={24} height={24}>
			<title>Share</title>
			<path
				fill="rgb(0, 106, 255)"
				stroke="none"
				d="m23.504 9.0047l-9.4663-6.7849c-0.2188-0.18177-0.53451-0.22356-0.79965-0.10586-0.26514 0.11771-0.42736 0.37168-0.41087 0.64327v3.4148c-2.9503 0.066134-5.77 1.1388-7.9168 3.0118-2.3605 2.2392-3.4984 5.3966-3.3895 9.5391 0.0061638 0.30779 0.2342 0.57373 0.55684 0.64938h0.18158c0.2629 2.775e-4 0.50471-0.13305 0.62947-0.34708 0.89579-1.5115 4.2005-6.2922 9.8174-6.2922h0.12105v3.2245l0.060526 0.44785 0.33895 0.15675c0.25053 0.11823 0.55234 0.092065 0.77474-0.067177l9.2242-6.6169 0.27842-0.25751v-0.61579zm-9.43 6.0571v-2.7431c4.845e-4 -0.35828-0.30312-0.65386-0.69-0.67177-5.3505-0.31349-8.8853 3.2021-10.604 5.4749 0.023449-2.6474 1.1158-5.1911 3.0626-7.132 2.0065-1.7327 4.6512-2.6935 7.3963-2.6871h0.14526c0.19332-1.3199e-4 0.37937-0.068163 0.52053-0.19033l0.21789-0.24632v-3.2021l7.9532 5.6989-8.0016 5.6989z"
			></path>
		</svg>
	);
};

export const Hide = () => {
	return (
		<svg
			viewBox="0 0 32 32"
			aria-hidden="true"
			width={22}
			height={22}
			style={{ marginBottom: "2px" }}
		>
			<title>Hide</title>
			<path
				fill="rgb(0, 106, 255)"
				stroke="none"
				d="M16 2a14 14 0 1014 14A14 14 0 0016 2zM6.85 23.74A12 12 0 0123.74 6.85L6.85 23.74zM16 28a11.89 11.89 0 01-7.74-2.85L25.15 8.26A12 12 0 0116 28z"
			></path>
		</svg>
	);
};

export const More = () => {
	return (
		<svg viewBox="0 0 32 32" aria-hidden="true" width={22} height={22}>
			<title>More</title>
			<g stroke="none">
				<path
					fill="rgb(0, 106, 255)"
					d="M16,14a2,2,0,1,1-2,2,2,2,0,0,1,2-2m0-2a4,4,0,1,0,4,4,4,4,0,0,0-4-4Z"
				></path>
				<path
					fill="rgb(0, 106, 255)"
					d="M6,14a2,2,0,1,1-2,2,2,2,0,0,1,2-2m0-2a4,4,0,1,0,4,4,4,4,0,0,0-4-4Z"
				></path>
				<path
					fill="rgb(0, 106, 255)"
					d="M26,14a2,2,0,1,1-2,2,2,2,0,0,1,2-2m0-2a4,4,0,1,0,4,4,4,4,0,0,0-4-4Z"
				></path>
			</g>
		</svg>
	);
};

export const Info = () => {
	return (
		<svg
			viewBox="0 0 32 32"
			aria-hidden="true"
			focusable="false"
			role="img"
			width={24}
			height={24}
			style={{ marginLeft: "5px" }}
		>
			<title>Information</title>
			<g stroke="none" fill="#1E3A89">
				<path d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm0,26A12,12,0,1,1,28,16,12,12,0,0,1,16,28Z"></path>
				<path d="M18.35,19.89h-.89V15.26A1.15,1.15,0,0,0,16.21,14H13.84a1.23,1.23,0,1,0,0,2.45h1.07v3.41H13.65a1.23,1.23,0,1,0,0,2.45h4.7a1.23,1.23,0,1,0,0-2.45Z"></path>
				<path d="M15.91,12.71a1.53,1.53,0,0,0,0-3.05,1.53,1.53,0,1,0,0,3.05Z"></path>
			</g>
		</svg>
	);
};

export const BackArrow = () => {
	return (
		<svg
			viewBox="0 0 32 32"
			aria-hidden="true"
			focusable="false"
			role="img"
			width={22}
			height={22}
			style={{ transform: 'rotate(90deg)' }} // Rotate the SVG to point left
		>
			<title>Chevron Left</title>
			<g stroke="none" fill="#1E3A89">
				<path d="M29.7 8.8a1 1 0 00-1.4 0L16 21 3.7 8.8a1 1 0 00-1.4 0 1 1 0 000 1.4l13 13a1 1 0 001.4 0l13-13a1 1 0 000-1.4z"></path>
			</g>
		</svg>
	);
};
