// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empty-search-wrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 51px;
  background-color: rgb(250, 250, 250);
  margin-top: 7px;
  border-radius: 3px 3px 0 0;
}
.empty-search-wrapper:hover {
  background-color: rgb(243, 250, 254);
}
.empty-search-wrapper:hover .current-location {
  border-bottom: none;
}
.empty-search-wrapper .splash-search-suggestion-icon {
  width: 67px !important;
}
.empty-search-wrapper .current-location {
  display: flex;
  width: 85% !important;
  align-items: center;
}
.empty-search-wrapper .current-location p {
  width: 95%;
}

.splash-search-history-list-item {
  display: flex;
  width: 100%;
}
.splash-search-history-list-item:hover {
  background-color: rgb(243, 250, 254);
}
.splash-search-history-list-item:hover div p {
  border-bottom: none;
}
.splash-search-history-list-item:last-child:hover {
  border-radius: 0 0 3px 3px;
}
.splash-search-history-list-item .suggestions-item-container {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/SearchBar/SplashSearchHistorySuggestions.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,2BAAA;EACA,WAAA;EACA,YAAA;EACA,oCAAA;EACA,eAAA;EACA,0BAAA;AACF;AACE;EACE,oCAAA;AACJ;AACI;EACE,mBAAA;AACN;AAGE;EACE,sBAAA;AADJ;AAIE;EACE,aAAA;EACA,qBAAA;EACA,mBAAA;AAFJ;AAII;EACE,UAAA;AAFN;;AAOA;EACE,aAAA;EACA,WAAA;AAJF;AAME;EACE,oCAAA;AAJJ;AAOM;EACE,mBAAA;AALR;AAUE;EACE,0BAAA;AARJ;AAWE;EACE,WAAA;AATJ","sourcesContent":[".empty-search-wrapper {\n  display: flex;\n  justify-content: flex-start;\n  width: 100%;\n  height: 51px;\n  background-color: rgb(250, 250, 250);\n  margin-top: 7px;\n  border-radius: 3px 3px 0 0;\n\n  &:hover {\n    background-color: rgb(243 250 254);\n\n    .current-location {\n      border-bottom: none;\n    }\n  }\n\n  .splash-search-suggestion-icon {\n    width: 67px !important;\n  }\n\n  .current-location {\n    display: flex;\n    width: 85% !important;\n    align-items: center;\n\n    p {\n      width: 95%;\n    }\n  }\n}\n\n.splash-search-history-list-item {\n  display: flex;\n  width: 100%;\n\n  &:hover {\n    background-color: rgb(243 250 254);\n\n    div {\n      p {\n        border-bottom: none;\n      }\n    }\n  }\n\n  &:last-child:hover {\n    border-radius: 0 0 3px 3px;\n  }\n\n  .suggestions-item-container {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
