// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.more-recommended-homes {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4%;
  color: #1F4494;
  font-weight: 600;
  font-family: "Open Sans";
  font-size: 16px;
  margin: 16px 0;
}
.more-recommended-homes:hover {
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/components/Splash/SplashPage.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;EACA,cAAA;EACA,gBAAA;EACA,wBAAA;EACA,eAAA;EACA,cAAA;AACF;AACE;EACE,0BAAA;AACJ","sourcesContent":[".more-recommended-homes {\n  width: 100vw;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 0.4%;\n  color: #1F4494;\n  font-weight: 600;\n  font-family: 'Open Sans';\n  font-size: 16px;\n  margin: 16px 0;\n\n  &:hover {\n    text-decoration: underline;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
