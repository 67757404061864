// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-container {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 1px solid #a7a6ab;
  padding: 10px;
  width: auto;
  border-radius: 4px;
  width: 146px;
}
.input-container input {
  border: none;
  width: 100%;
}
.input-container input:focus {
  outline: none;
  border: none;
}

.focused {
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 1px, rgb(166, 229, 255) 0px 0px 2px 3px, rgb(0, 106, 255) 0px 0px 2px 4px;
}

.hovered {
  outline: 1px solid #006aff;
}`, "",{"version":3,"sources":["webpack://./src/components/SearchBar/FilterButtons/PriceRange/Input.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,0BAAA;EACA,aAAA;EACA,WAAA;EACA,kBAAA;EACA,YAAA;AACF;AACE;EACE,YAAA;EACA,WAAA;AACJ;AACI;EACE,aAAA;EACA,YAAA;AACN;;AAIA;EACI,oHAAA;AADJ;;AAKA;EACE,0BAAA;AAFF","sourcesContent":[".input-container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  outline: 1px solid #a7a6ab;\n  padding: 10px;\n  width: auto;\n  border-radius: 4px;\n  width: 146px;\n\n  input {\n    border: none;\n    width: 100%;\n\n    &:focus {\n      outline: none;\n      border: none;\n    }\n  }\n}\n\n.focused {\n    box-shadow: rgb(255 255 255) 0px 0px 0px 1px,\n      rgb(166 229 255) 0px 0px 2px 3px, rgb(0 106 255) 0px 0px 2px 4px;\n}\n\n.hovered {\n  outline: 1px solid #006aff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
