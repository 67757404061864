// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cards-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: rgb(249, 249, 251);
  height: 664px;
  width: 100%;
  padding: 64px 0px;
  gap: 25px;
}`, "",{"version":3,"sources":["webpack://./src/components/Cards/style/container.scss","webpack://./src/components/Cards/style/mixins.scss"],"names":[],"mappings":"AAEA;ECDI,aAAA;EACA,mBAAA;EACA,uBAAA;EDCA,oCAAA;EACA,aAAA;EACA,WAAA;EACA,iBAAA;EACA,SAAA;AACJ","sourcesContent":["@import \"mixins\";\n\n.cards-container {\n    @include flexCenter;\n    background-color: rgb(249 249 251);\n    height: 664px;\n    width: 100%;\n    padding: 64px 0px;\n    gap: 25px;\n\n}\n\n","@mixin flexCenter() {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
