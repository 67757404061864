// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-listing-type-wrapper li {
  font-size: 14px;
}
.home-listing-type-wrapper .dropdown {
  background-color: white;
  z-index: 2;
  display: flex;
  flex-direction: column;
  border-radius: 0px 0px 5px 5px;
  position: absolute;
  margin-top: 7px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
.home-listing-type-wrapper .non-for-sale-dropdown {
  padding: 0px;
  margin: 0px;
}`, "",{"version":3,"sources":["webpack://./src/components/SearchBar/FilterButtons/DropDown.scss"],"names":[],"mappings":"AACE;EACE,eAAA;AAAJ;AAGE;EACE,uBAAA;EACA,UAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,kBAAA;EACA,eAAA;EACA,uFAAA;AADJ;AAKE;EACE,YAAA;EACA,WAAA;AAHJ","sourcesContent":[".home-listing-type-wrapper {\n  li {\n    font-size: 14px;\n  }\n\n  .dropdown {\n    background-color: white;\n    z-index: 2;\n    display: flex;\n    flex-direction: column;\n    border-radius: 0px 0px 5px 5px;\n    position: absolute;\n    margin-top: 7px;\n    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,\n      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;\n  }\n\n  .non-for-sale-dropdown {\n    padding: 0px;\n    margin: 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
