// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-type-container {
  display: flex;
  flex-direction: column;
}
.home-type-container .deselect-all-title-container {
  display: fles;
  flex-direction: column;
  background: #f5f5f5;
  color: #596b82;
  padding: 12px 16px;
  font-weight: 700;
}
.home-type-container .deselect-all-title-container h6 {
  font-size: 14px;
}
.home-type-container .deselect-all-title-container .deselect-btn-container {
  margin-top: 15px;
  display: flex;
  align-items: center;
  width: 178px;
  gap: 10px;
}
.home-type-container .deselect-all-title-container .deselect-btn-container:hover .deselect-text {
  text-decoration: underline;
}
.home-type-container .deselect-all-title-container .deselect-btn-container .deselect-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 70%;
  width: 20px !important;
  height: 20px !important;
  background: #0d4599;
  color: white;
}
.home-type-container .deselect-all-title-container .deselect-btn-container .deselect-btn .icon {
  width: 12px;
}
.home-type-container .deselect-all-title-container .deselect-btn-container h6 {
  font-size: 14px;
  font-weight: 700;
  color: #0d4599;
  -webkit-user-select: none;
          user-select: none;
}
.home-type-container .home-type-checkbox-container {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 12px 16px;
}
.home-type-container .home-type-checkbox-container input {
  width: 20px;
  border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/SearchBar/FilterButtons/HomeType/HomeType.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,cAAA;EACA,kBAAA;EACA,gBAAA;AACJ;AACI;EACE,eAAA;AACN;AAEI;EACE,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;EACA,SAAA;AAAN;AAEM;EACE,0BAAA;AAAR;AAGM;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AADR;AAGQ;EACE,WAAA;AADV;AAKM;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,yBAAA;UAAA,iBAAA;AAHR;AAQE;EACE,aAAA;EACA,mBAAA;EACA,QAAA;EACA,kBAAA;AANJ;AAQI;EACE,WAAA;EACA,mBAAA;AANN","sourcesContent":[".home-type-container {\n  display: flex;\n  flex-direction: column;\n\n  .deselect-all-title-container {\n    display: fles;\n    flex-direction: column;\n    background: #f5f5f5;\n    color: #596b82;\n    padding: 12px 16px;\n    font-weight: 700;\n\n    h6 {\n      font-size: 14px;\n    }\n\n    .deselect-btn-container {\n      margin-top: 15px;\n      display: flex;\n      align-items: center;\n      width: 178px;\n      gap: 10px;\n\n      &:hover .deselect-text {\n        text-decoration: underline;\n      }\n\n      .deselect-btn {\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        border-radius: 70%;\n        width: 20px !important;\n        height: 20px !important;\n        background: #0d4599;\n        color: white;\n\n        .icon {\n          width: 12px;\n        }\n      }\n\n      h6 {\n        font-size: 14px;\n        font-weight: 700;\n        color: #0d4599;\n        user-select: none;\n      }\n    }\n  }\n\n  .home-type-checkbox-container {\n    display: flex;\n    align-items: center;\n    gap: 5px;\n    padding: 12px 16px;\n\n    input {\n      width: 20px;\n      border-radius: 10px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
