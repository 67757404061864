// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-component-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 55px;
  padding: 0px 12px;
  cursor: pointer;
  gap: 15px;
}
.search-component-wrapper .focused-wrapper {
  margin-top: 10px;
  align-self: flex-start;
}
.search-component-wrapper .search-input-wrapper {
  z-index: 1;
}
.search-component-wrapper .search-input-wrapper .search-input {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  width: 298px;
  max-width: 298px;
  height: 42px;
  border: 1px groove lightgray;
  border-radius: 4px;
  padding: 5px 0px 5px 8px;
}
.search-component-wrapper .search-input-wrapper .search-input:hover {
  border-color: #006aff !important;
}
.search-component-wrapper .search-input-wrapper .search-input .text-input {
  flex: 1 1;
  overflow: hidden;
}
.search-component-wrapper .search-input-wrapper .search-input .text-input:focus {
  outline: none;
}
.search-component-wrapper .search-input-wrapper .focused {
  border: none;
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 1px, rgb(166, 229, 255) 0px 0px 2px 3px, rgb(0, 106, 255) 0px 0px 2px 4px;
  height: auto;
  z-index: 2;
}
.search-component-wrapper .search-input-wrapper .indexSearchDropdown {
  font-size: 16px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 8px;
  position: absolute;
  min-width: 298px; /* Match the width of .search-input */
  width: auto;
  margin-top: 8px;
  background-color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/SearchBar/IndexSearchInput.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,eAAA;EACA,SAAA;AACF;AACE;EACI,gBAAA;EACA,sBAAA;AACN;AAEE;EACE,UAAA;AAAJ;AAEI;EACE,sBAAA;EACA,aAAA;EACA,8BAAA;EACA,YAAA;EACA,gBAAA;EACA,YAAA;EACA,4BAAA;EACA,kBAAA;EACA,wBAAA;AAAN;AAEM;EACE,gCAAA;AAAR;AAIM;EACE,SAAA;EACA,gBAAA;AAFR;AAIQ;EACE,aAAA;AAFV;AAQI;EACE,YAAA;EACA,oHAAA;EAEA,YAAA;EACA,UAAA;AAPN;AAUI;EACE,eAAA;EACA,2CAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA,EAAA,qCAAA;EACA,WAAA;EACA,eAAA;EACA,sBAAA;AARN","sourcesContent":[".search-component-wrapper {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  height: 55px;\n  padding: 0px 12px;\n  cursor: pointer;\n  gap: 15px;\n\n  .focused-wrapper {\n      margin-top: 10px;\n      align-self: flex-start;\n  }\n\n  .search-input-wrapper {\n    z-index: 1;\n\n    .search-input {\n      background-color: #fff;\n      display: flex;\n      justify-content: space-between;\n      width: 298px;\n      max-width: 298px;\n      height: 42px;\n      border: 1px groove lightgray;\n      border-radius: 4px;\n      padding: 5px 0px 5px 8px;\n\n      &:hover {\n        border-color: #006aff !important;\n      }\n\n\n      .text-input {\n        flex: 1;\n        overflow: hidden;\n\n        &:focus {\n          outline: none;\n        }\n      }\n\n    }\n\n    .focused {\n      border: none;\n      box-shadow: rgb(255 255 255) 0px 0px 0px 1px,\n        rgb(166 229 255) 0px 0px 2px 3px, rgb(0 106 255) 0px 0px 2px 4px;\n      height: auto;\n      z-index: 2;\n    }\n\n    .indexSearchDropdown {\n      font-size: 16px;\n      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;\n      border-radius: 8px;\n      position: absolute;\n      min-width: 298px; /* Match the width of .search-input */\n      width: auto;\n      margin-top: 8px;\n      background-color: #fff;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
