// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-container {
  display: flex;
  flex-direction: column;
}
.search-container .search-word-row {
  display: grid;
  grid-template-columns: auto minmax(0, 1fr) auto;
  align-items: center;
  gap: 10px;
}
.search-container .search-word-wrapper {
  display: flex;
  align-items: center;
  padding: 4px 10px;
  border-radius: 4px;
  background-color: rgb(223, 227, 230);
  white-space: nowrap;
  gap: 5px;
}
.search-container .search-word-wrapper p {
  font-weight: bold;
  font-size: 14px;
}
.search-container .search-word-wrapper .search-word-close-icon {
  width: 16px;
  height: 16px;
}
.search-container .add-another-container p {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  opacity: 0.6;
}
.search-container .icon-container {
  opacity: 0.7;
  margin: 0 15px 0 5px;
}

.text-input {
  overflow: hidden;
  margin-top: 12px;
}
.text-input:focus {
  outline: none;
}

.no-search-word {
  margin-top: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/SearchBar/SearchInputContainer.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AACE;EACE,aAAA;EACA,+CAAA;EACA,mBAAA;EACA,SAAA;AACJ;AAEE;EACE,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,kBAAA;EACA,oCAAA;EACA,mBAAA;EACA,QAAA;AAAJ;AAEI;EACE,iBAAA;EACA,eAAA;AAAN;AAGI;EACE,WAAA;EACA,YAAA;AADN;AAMI;EACE,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,WAAA;EACA,uBAAA;EACA,YAAA;AAJN;AAQE;EACE,YAAA;EACA,oBAAA;AANJ;;AAUA;EACE,gBAAA;EACA,gBAAA;AAPF;AASE;EACE,aAAA;AAPJ;;AAWA;EACE,aAAA;AARF","sourcesContent":[".search-container {\n  display: flex;\n  flex-direction: column;\n\n  .search-word-row {\n    display: grid;\n    grid-template-columns: auto minmax(0, 1fr) auto;\n    align-items: center;\n    gap: 10px;\n  }\n\n  .search-word-wrapper {\n    display: flex;\n    align-items: center;\n    padding: 4px 10px;\n    border-radius: 4px;\n    background-color: rgb(223 227 230);\n    white-space: nowrap;\n    gap: 5px;\n\n    p {\n      font-weight: bold;\n      font-size: 14px;\n    }\n\n    .search-word-close-icon {\n      width: 16px;\n      height: 16px;\n    }\n  }\n\n  .add-another-container {\n    p {\n      position: relative;\n      white-space: nowrap;\n      overflow: hidden;\n      width: 100%;\n      text-overflow: ellipsis;\n      opacity: 0.6;\n    }\n  }\n\n  .icon-container {\n    opacity: 0.7;\n    margin: 0 15px 0 5px;\n  }\n}\n\n.text-input {\n  overflow: hidden;\n  margin-top: 12px;\n\n  &:focus {\n    outline: none;\n  }\n}\n\n.no-search-word {\n  margin-top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
