// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.listing_header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 15px 22px 15px 22px;
  justify-items: center;
  align-items: center;
}
.listing_header .back-to-listing {
  cursor: pointer;
}
.listing_header .back-to-listing:hover {
  text-decoration: underline;
}
.listing_header .back-to-listing {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: "Open Sans";
  gap: 10px;
}
.listing_header ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  color: rgb(0, 106, 255);
  padding: 0;
  margin: 0;
  width: 100%;
}
.listing_header li {
  display: flex;
  align-items: center;
  gap: 5px;
}
.listing_header li:hover {
  text-decoration: underline;
  cursor: pointer;
}

.listing_header > :first-child {
  justify-self: start;
}`, "",{"version":3,"sources":["webpack://./src/components/ShowListing/style/ListingHeader.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kCAAA;EACA,4BAAA;EACA,qBAAA;EACA,mBAAA;AACF;AACE;EACE,eAAA;AACJ;AACI;EACE,0BAAA;AACN;AAGE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,wBAAA;EACA,SAAA;AADJ;AAIE;EACE,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,6BAAA;EACA,mBAAA;EACA,SAAA;EACA,uBAAA;EACA,UAAA;EACA,SAAA;EACA,WAAA;AAFJ;AAKE;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AAHJ;AAKI;EACE,0BAAA;EACA,eAAA;AAHN;;AAQA;EACE,mBAAA;AALF","sourcesContent":[".listing_header {\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr;\n  padding: 15px 22px 15px 22px;\n  justify-items: center;\n  align-items: center;\n\n  .back-to-listing {\n    cursor: pointer;\n\n    &:hover {\n      text-decoration: underline;\n    }\n  }\n\n  .back-to-listing {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-size: 14px;\n    font-family: 'Open Sans';\n    gap: 10px;\n  }\n\n  ul {\n    list-style: none;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-around;\n    align-items: center;\n    gap: 20px;\n    color: rgb(0, 106, 255);\n    padding: 0;\n    margin: 0;\n    width: 100%;\n  }\n\n  li {\n    display: flex;\n    align-items: center;\n    gap: 5px;\n\n    &:hover {\n      text-decoration: underline;\n      cursor: pointer;\n    }\n  }\n}\n\n.listing_header > :first-child {\n  justify-self: start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
