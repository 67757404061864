// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map_container {
  position: relative;
  height: 100%;
}

/* Pseudo-element for right shadow */
.map_container::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 6px; /* Control the apparent depth */
  height: 100%;
  background: linear-gradient(to left, rgba(74, 74, 74, 0.3) 20%, transparent 80%);
  z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/Map/map.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;AACF;;AAEA,oCAAA;AACA;EACE,WAAA;EACA,kBAAA;EACA,MAAA;EACA,QAAA;EACA,UAAA,EAAA,+BAAA;EACA,YAAA;EACA,gFAAA;EAIA,UAAA;AAFF","sourcesContent":[".map_container {\n  position: relative;\n  height: 100%;\n}\n\n/* Pseudo-element for right shadow */\n.map_container::after {\n  content: '';\n  position: absolute;\n  top: 0;\n  right: 0;\n  width: 6px; /* Control the apparent depth */\n  height: 100%;\n  background: linear-gradient(to left,\n      rgba(74, 74, 74, 0.3) 20%,\n      transparent 80%\n    );\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
