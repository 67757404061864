// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  height: 445px;
  grid-gap: 8px;
}

.gallery-item {
  display: block;
  width: 100%;
  height: 100%;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.large {
  grid-area: large;
  border-radius: 5px 0px 0px 5px;
  overflow: hidden;
}

.gallery {
  grid-template-areas: "large large small1 small2" "large large small3 small4";
}

.large {
  border-radius: 20px 0 0 20px;
}

.small:nth-child(2) {
  border-radius: 0 20px 0 0;
  overflow: hidden;
}

.small:nth-child(4) {
  border-radius: 0 0 20px 0;
  overflow: hidden;
}

.small:nth-child(1) {
  grid-area: small1;
}

.small:nth-child(2) {
  grid-area: small2;
}

.small:nth-child(3) {
  grid-area: small3;
}

.small:nth-child(4) {
  grid-area: small4;
}`, "",{"version":3,"sources":["webpack://./src/components/ShowListing/style/gallery.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,qCAAA;EACA,kCAAA;EACA,aAAA;EACA,aAAA;AACJ;;AAEA;EACI,cAAA;EACA,WAAA;EACA,YAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;EACA,iBAAA;EACA,cAAA;AACJ;;AAEA;EACI,gBAAA;EACA,8BAAA;EACA,gBAAA;AACJ;;AAEA;EACI,4EACI;AAAR;;AAIA;EACI,4BAAA;AADJ;;AAIA;EACI,yBAAA;EACA,gBAAA;AADJ;;AAIA;EACI,yBAAA;EACA,gBAAA;AADJ;;AAIA;EAAsB,iBAAA;AAAtB;;AACA;EAAsB,iBAAA;AAGtB;;AAFA;EAAsB,iBAAA;AAMtB;;AALA;EAAsB,iBAAA;AAStB","sourcesContent":[".gallery {\n    display: grid;\n    grid-template-columns: repeat(4, 1fr);\n    grid-template-rows: repeat(2, 1fr);\n    height: 445px;\n    grid-gap: 8px;\n}\n\n.gallery-item {\n    display: block;\n    width: 100%;\n    height: 100%;\n}\n\n.gallery-item img {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n    display: block;\n}\n\n.large {\n    grid-area: large;\n    border-radius: 5px 0px 0px 5px;\n    overflow: hidden;\n}\n\n.gallery {\n    grid-template-areas:\n        \"large large small1 small2\"\n        \"large large small3 small4\";\n}\n\n.large {\n    border-radius: 20px 0 0 20px;\n}\n\n.small:nth-child(2) {\n    border-radius: 0 20px 0 0;\n    overflow: hidden;\n}\n\n.small:nth-child(4) {\n    border-radius: 0 0 20px 0;\n    overflow: hidden;\n}\n\n.small:nth-child(1) { grid-area: small1; }\n.small:nth-child(2) { grid-area: small2; }\n.small:nth-child(3) { grid-area: small3; }\n.small:nth-child(4) { grid-area: small4; }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
