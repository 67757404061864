// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.splat_listing_container {
  padding-top: 52px;
  height: 420px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/ListingsCarousel/ListingsCarousel.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,mBAAA;AACF","sourcesContent":[".splat_listing_container {\n  padding-top: 52px;\n  height: 420px;\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  align-items: center;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
