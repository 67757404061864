// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-container .modal-area {
  background-color: #b21e1e;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
  z-index: 11;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.close-button-container {
  display: flex;
  justify-content: flex-end;
}
.close-button-container ._modal-close {
  padding: 5px;
  cursor: pointer;
}
.close-button-container ._modal-close:hover {
  background-color: rgb(233, 233, 233);
  border-radius: 50%;
}
.close-button-container ._modal-close ._modal-close-icon {
  width: 23px;
  height: 23px;
  fill: transparent;
  stroke: black;
  stroke-linecap: round;
  stroke-width: 4;
}

.scroll-lock {
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/Modal.scss"],"names":[],"mappings":"AACC;EACC,yBAAA;EACA,2CAAA;EACA,WAAA;EACA,gBAAA;EACA,iCAAA;AAAF;;AAKA;EACC,aAAA;EACA,yBAAA;AAFD;AAIC;EACC,YAAA;EACA,eAAA;AAFF;AAKE;EACC,oCAAA;EACA,kBAAA;AAHH;AAME;EACC,WAAA;EACA,YAAA;EACA,iBAAA;EACA,aAAA;EACA,qBAAA;EACA,eAAA;AAJH;;AAUA;EACC,gBAAA;AAPD","sourcesContent":[".modal-container {\n\t.modal-area {\n\t\tbackground-color: #b21e1e;\n\t\tbox-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);\n\t\tz-index: 11;\n\t\toverflow-y: auto;\n\t\t-webkit-overflow-scrolling: touch;\n\t}\n}\n\n\n.close-button-container {\n\tdisplay: flex;\n\tjustify-content: flex-end;\n\n\t._modal-close {\n\t\tpadding: 5px;\n\t\tcursor: pointer;\n\n\n\t\t&:hover {\n\t\t\tbackground-color: rgb(233, 233, 233);\n\t\t\tborder-radius: 50%;\n\t\t}\n\n\t\t._modal-close-icon {\n\t\t\twidth: 23px;\n\t\t\theight: 23px;\n\t\t\tfill: transparent;\n\t\t\tstroke: black;\n\t\t\tstroke-linecap: round;\n\t\t\tstroke-width: 4;\n\t\t}\n\t}\n}\n\n\n.scroll-lock {\n\toverflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
