// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.splash-search-suggestion-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
}`, "",{"version":3,"sources":["webpack://./src/components/SearchBar/assets/SuggestionIcon.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;AACJ","sourcesContent":[".splash-search-suggestion-icon {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 75px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
